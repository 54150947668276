<template lang="pug">
    .main-wrapper.nps-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Pesquisa de Satisfação</b>

        Panel.my-2(header="Filtros" :toggleable="true" :collapsed="windowInnerWidth < 576" mode="indeterminate")
            .p-grid.p-fluid
                //.p-col-12.p-md-6
                    label.form-label Nome fantasia:
                    .p-inputgroup
                        InputText(placeholder="Nome fantasia"
                            @keyup.enter.native="applyFilters()"
                            @input="(val) => val || applyFilters()"
                            v-model="filters.nm_fantasia"
                        )
                        Button(icon="jam jam-search" @click="applyFilters()")

                //.p-col-12.p-md-6
                    label.form-label CNPJ:
                    .p-inputgroup
                        InputMask(mask="99.999.999/9999-99"
                            v-model="filters.nr_cnpj"
                            @input="(val) => val || applyFilters()"
                            //:autoClear="false")
                        Button(icon="jam jam-search" @click="applyFilters()")

        .ta-right.my-2
            Button(label="Adicionar" icon="jam jam-plus" @click="$router.push('/nps/perguntas/salvar/0/')")

        ProgressBar(v-if="waiting" mode="indeterminate")
        div(v-else-if="! list.length")
            p.ta-center.text-secondary(style="margin-top: 40px;") Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style="position: relative")
                            .ta-left
                                p <b>Nome fantasia:</b> {{ props.data.nm_fantasia }}
                                p <b>Razão social:</b> {{ props.data.nm_razao_social }}
                                p <b>CNPJ:</b> {{ props.data.nr_cnpj_f }}
                                p <b>Telefone:</b> {{ props.data.nr_telefone_f }}

            Paginator.dataview(
                :rows="paginator.per_page"
                :first="(paginator.page - 1) * paginator.per_page"
                :totalRecords="paginator.count"
                @page="onPage($event)"
            )

            Panel.datatable(header="Lista de Empresas")
                Paginator.mb-1(
                    :rows="paginator.per_page"
                    :first="(paginator.page - 1) * paginator.per_page"
                    :totalRecords="paginator.count"
                    @page="onPage($event)"
                )
                DataTable(:value="list")

                    Column(headerStyle="width: 4em;" bodyStyle="text-align: center;")
                        template(#body="props")
                            ProgressSpinner.progress-small(v-if="desabilitarId === props.data.id" strokeWidth="6")
                            .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click="desabilitar(props.data)")
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle="width: 8%;" field="id" header="Código" bodyStyle="text-align: center")
                    Column(headerStyle="width: 15%;" field="ie_tipo" header="Tipo de Pergunta")
                        template(#body='props')
                            p(v-if='props.data.ie_tipo == "C"') Comentário
                            p(v-if='props.data.ie_tipo == "N"') Nota
                    Column(headerStyle="width: 30%;" field="ds_pergunta" header="Pergunta")
                    Column(headerStyle="width: 20%" field="ie_tipo_avaliado" header="Tipo" bodyStyle="text-align: center")
                        template(#body='props')
                            p(v-if='props.data.ie_tipo_avaliado === "ES"') Estabelecimento
                            p(v-if='props.data.ie_tipo_avaliado === "ME"') Médico
                            p(v-if='props.data.ie_tipo_avaliado === "NA"') Todos
                    Column(headerStyle="width: 20%;" field="ie_categoria" header="Categoria" bodyStyle="text-align: center")
                        template(#body='props')
                            p(v-if='props.data.ie_categoria === "C"') Consulta
                            p(v-if='props.data.ie_categoria === "E"') Exame
                    Column(headerStyle="width: 20%;" header="Ações" bodyStyle="text-align: center")
                        template(#body="props")
                            Button.p-button-raised.p-button-rounded.mr-1(
                                v-tooltip.top="'Editar'"
                                icon="jam jam-write"
                                @click="$router.push(`/nps/perguntas/salvar/${ props.data.id }/`)"
                            )

                Paginator(
                    :rows="paginator.per_page"
                    :first="(paginator.page - 1) * paginator.per_page"
                    :totalRecords="paginator.count"
                    @page="onPage($event)"
                )

</template>

<style lang="scss">
.nps-listar {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
    }
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 23px;
        height: 23px;
        line-height: 36px;
        border-radius: 50%;
        background-color: #94c860;
        border: 1px solid #84b553;
        transition: 250ms;
        cursor: pointer;
        padding-right:10px;
        &:hover { background-color: #84b553; }
        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
            &:hover { background-color: #b33030; }
        }
        .jam {
            color: #fff;
            margin-left:2px;
        }
    }
    .progress-small {
        width: 25px;
        height: auto;
    }
}
</style>

<script>
    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import DataView from "primevue/dataview"
    import InputMask from "primevue/inputmask"
    import InputText from "primevue/inputtext"
    import Paginator from "primevue/paginator"
    import Panel from "primevue/panel"
    import ProgressBar from "primevue/progressbar"
    import ProgressSpinner from "primevue/progressspinner"
    import Tooltip from "primevue/tooltip"

    import wsConfigs from "@/middleware/configs"
    import Nps from "../../middleware/controllers/Nps";

    export default {
        components: { Button, Column, DataTable, DataView, InputText,
            InputMask, Paginator, Panel, ProgressBar, ProgressSpinner },
        directives: { tooltip: Tooltip },
        created() {
            this.applyFilters()
        },
        data() {
            return {
                list: [],
                waiting: false,
                waitingStatus: [],
                windowInnerWidth: window.innerWidth,
                filters: {},
                desabilitarId: 0,
                paginator: {
                    count: 0,
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                }
            }
        },
        methods: {
            getList(params) {
                this.waiting = true
                Nps.findAll(params).then(response => {
                    this.waiting = false
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                })

            },
            // desabilitar(data) {
            //     console.log(data);
            // },
            applyFilters (page) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) {
                    this.$router.replace( { query: { pg: this.paginator.page } } )
                }
                Object.keys(this.filters).forEach(key => { if (this.filters[key]) params[key] = this.filters[key] })
                this.getList(params)
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
        }
    }
</script>
