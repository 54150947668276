import GenericDAO from './../generic-dao'

const findAll = (params) => GenericDAO.find('/medclub/pesquisa/pergunta/', params)
const find = (id) => GenericDAO.find(`/medclub/pesquisa/pergunta/${ id }/`)
const remove = (id) => GenericDAO.remove(`/medclub/pesquisa/pergunta/${ id }/`)
const save = (dataSend) => GenericDAO.save(`/medclub/pesquisa/pergunta/`, dataSend)

export default {
    find,
    findAll,
    remove,
    save
}
