import axios from 'axios'
import configs from './configs'

const failed = new Promise(resolve => { resolve(false) })

const find = (pathUrl, params, _configs, cancelToken) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {

        let url = `${ configs.baseUrl }${ pathUrl }`
        if (!params) params = {}
        if (!_configs) _configs = {}

        if (! _configs.ignorarEstabelecimento) params.cd_estabelecimento =
            usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id

        if (_configs.usarCidade) params.cd_cidade = usuario?.opcoes_cidade[usuario.idxCidadeSelecionada]?.id

        url = `${ url }?${ new URLSearchParams(params).toString() }`
        // console.log(url);
        // console.log({
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //     'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
        // });

		const token = {}
		if (cancelToken) token['cancelToken'] = cancelToken

        return axios.get(url, { headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }`
            }, ...token }
        )
        .then(response => response)
        .catch(configs.errorHandler)
    }
}

const save = (pathUrl, dataSend, _configs) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed

    if (!dataSend) dataSend = {}
    if (!_configs) _configs = {}

    let cdEstabelecimentoQuery = ''
    if (! _configs.ignorarEstabelecimento) {
        dataSend.cd_estabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id
        cdEstabelecimentoQuery = `?cd_estabelecimento=${ dataSend.cd_estabelecimento }`
    }

    let headers = { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` }
    let dataSend_formData = new FormData()
	let data = dataSend
    if (dataSend.aq_foto && ! _configs.formDataIgnore) {
        // headers['Access-Control-Allow-Headers'] = '*'
        // headers['Access-Control-Allow-Origin'] = '*'
        // headers['Access-Control-Max-Age'] = '0'
        // headers['Access-Control-Allow-Methods'] = 'OPTIONS, POST, PUT'
        headers['Content-type'] = 'multipart/form-data'
        Object.keys(dataSend).forEach(k => dataSend_formData.set(k, dataSend[k]))
        data = dataSend_formData
    }

    if (dataSend.id) {
        return axios.patch(
            `${ configs.baseUrl }${ pathUrl }${ dataSend.id }/${ cdEstabelecimentoQuery }`,
                data, { headers }
        )
        .then(response => response)
        .catch(configs.errorHandler)
    } else {
        return axios.post(
            `${ configs.baseUrl }${ pathUrl }${ cdEstabelecimentoQuery }`,
			data, { headers }
        )
        .then(response => response)
        .catch(configs.errorHandler)
    }
}

const remove = (pathUrl, params, body, _configs) => {
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    if (!usuario) return failed
    else {

        let url = `${ configs.baseUrl }${ pathUrl }`
        if (!params) params = {}
        if (!_configs) _configs = {}
        if (! _configs.ignorarEstabelecimento) {
            params.cd_estabelecimento = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id
        }

        url = `${ url }?${ new URLSearchParams(params).toString() }`

        let headers = { 'Authorization': `${ usuario.tokenType } ${ usuario.accessToken }` }

        if (body) _configs.data = body
        else headers['Content-Type'] = 'application/x-www-form-urlencoded'

        return axios.delete(url, { headers })
            .then(response => response)
            .catch(configs.errorHandler)
    }
}

export default {
    find,
    save,
    remove
}
